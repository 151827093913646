import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { StatefulInput } from "baseui/input"
import { Cell } from "baseui/layout-grid"
import { Button } from "baseui/button"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import Container from "../components/UI/Container"
import { Title, SubTitle } from "../components/typography"
import FlushedGrid from "../components/FlushedGrid"
import { logLandingEvent, logNewsletterSignUp } from "../utils/analytics"

const content = {
  title: "Motif Early Signup",
  description:
    "A modern web based Gephi to supercharge network investigations! Auto-graph import, time-series playback, custom visual options, add-on widgets to have the pixel perfect network plot for web or print",
  imgSrc: "https://cylynx.imgix.net/uploads/motif-mockup.gif",
}

export default () => {
  const { title, description, imgSrc } = content
  const [isRegistered, setIsRegistered] = useState(false)
  const { handleSubmit, register } = useForm()

  useEffect(() => {
    logLandingEvent("Motif-Signup", title, null)
  }, [])

  // eslint-disable-next-line no-shadow
  const onSubmit = data => {
    axios
      .post("https://usebasin.com/f/0a0b0bb2dfa7.json", {
        ...data,
        interestedIn: "Motif",
        title,
        description,
      })
      .then(() => {
        logLandingEvent("Motif-Signup   ", title, "registered")
      })
    setIsRegistered(true)
    logNewsletterSignUp()
  }

  return (
    <Layout isSimple>
      <SEOPage title={title} description={description} />
      <Block marginTop="200px" />
      <Container>
        <FlushedGrid gridGutters={64}>
          <Cell span={[4, 4, 6]}>
            {isRegistered ? (
              <>
                <Title marginBottom="48px">Thank you for registering!</Title>
                <SubTitle marginBottom="48px">
                  Check your inbox for the latest updates
                </SubTitle>
              </>
            ) : (
              <>
                <Title marginBottom="48px">Thank you for your interest!</Title>
                <SubTitle marginBottom="48px">
                  Enter your email below to receive the latest updates and be
                  the first to test out Motif
                </SubTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl label="Email*">
                    <StatefulInput
                      required
                      type="email"
                      name="email"
                      placeholder=""
                      inputRef={register}
                    />
                  </FormControl>
                  {/* Honey Pot */}
                  <div style={{ position: "absolute", left: "-5000px" }}>
                    <input
                      type="checkbox"
                      name="white_tiger_lynx"
                      value="1"
                      tabIndex="-1"
                      autoComplete="no"
                    />
                  </div>
                  <Button id="motif-signup-success">Keep me updated</Button>
                </form>
              </>
            )}
          </Cell>
          <Cell span={[4, 4, 6]} align="center">
            <Block
              display={["none", "none", "block"]}
              margin="0 auto"
              width="60%"
            >
              <img src={imgSrc} />
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
      <Block marginTop="200px" />
    </Layout>
  )
}
